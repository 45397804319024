<template lang="pug">
.card(style="background: white")
  .card-body
    .row.mb-3
      .col-6
        multiselect(
          v-model="selectedCompany",
          deselect-label="Unselect",
          track-by="name",
          label="name",
          placeholder="Select company to show departments of",
          :options="companies",
          :searchable="true",
          :allow-empty="true"
          :preselect-first="companies.length === 1"
          :disabled="companies.length === 1"
        )
      .col-6
        multiselect(
          v-model="selectedDepartment",
          deselect-label="Unselect",
          track-by="name",
          label="name",
          placeholder="Select department to filter jobs",
          :options="departments",
          :searchable="true",
          :allow-empty="true"
        )
    .row
      .col-12
        b-table(
          :items="jobs"
          :fields="fields"
          @row-clicked="onRowClicked"
          show-empty
          hover
          tbody-tr-class="cursor"
          )
          template(v-slot:cell(empty)="scope")
            p.text-center {{selectedCompany && selectedDepartment ? 'Empty' : 'Select company and project to show jobs'}}
          
          // headers
          template(v-slot:head(new)="data")
            font-awesome-icon(:icon="iconReferral" v-b-tooltip="'Referrals'" class="text-secondary mr-1")
            span(class="text-secondary")
              | Referrals
          
          template(v-slot:head(accepted)="data")
            font-awesome-icon(:icon="iconAccepted" v-b-tooltip="'Accepted'" class="text-success mr-1")
            span(class="text-success")
              | Applications

          template(v-slot:head(rejected)="data")
            font-awesome-icon(:icon="iconRejected" v-b-tooltip="'Rejected'" class="text-danger mr-1")
            span(class="text-danger")
              | Rejections

          template(v-slot:head(pending)="data")
            font-awesome-icon(:icon="iconPending" v-b-tooltip="'Pending'" class="text-warning mr-1")
            span(class="text-warning")
              | Pending

          // cells
          template(v-slot:cell(new)="data")
            span  {{ data.item.stats.new ? data.item.stats.new : 0 }}
          
          template(v-slot:cell(accepted)="data")
            span(style="color: #28a745") {{ data.item.stats.accepted ? data.item.stats.accepted : 0 }}

          template(v-slot:cell(rejected)="data")
            span(style="color: #dc3545") {{ data.item.stats.rejected ? data.item.stats.rejected : 0 }}

          template(v-slot:cell(pending)="data")
            span(style="color: #ffc107") {{ data.item.stats.pending ? data.item.stats.pending : 0 }}

</template>
<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
import { faUserCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faHourglass } from '@fortawesome/free-regular-svg-icons'
import { ROUTE, STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'JobList',

    components: {
        Multiselect,
    },

    computed: {
        ...mapState(['userAccount']),

        iconAccepted: () => faPaperPlane,
        iconRejected: () => faTimesCircle,
        iconPending: () => faHourglass,
        iconReferral: () => faUserCheck,
    },

    watch: {
        userAccount: {
            handler() {
                if (this.userAccount) {
                    this.fetchAllCompanies()
                }
            },
            immediate: true,
        },

        companies() {
            if (this.companies.length > 0) {
                const company = this.companies.find((obj) => obj.id === this.editCompany)
                this.selectedCompany = company || this.companies[0]
            }
        },

        selectedCompany(val) {
            this.selectedDepartment = null
            val ? this.fetchDepartmentsFromCompany() : (this.departments = [])
            val ? this.fetchJobsFromCompany() : (this.jobs = [])
        },

        selectedDepartment(val) {
            val ? this.fetchJobsFromDepartment() : this.fetchJobsFromCompany()
        },
    },

    data() {
        return {
            companies: [],
            departments: [],
            jobs: [],

            selectedCompany: null,
            selectedDepartment: null,

            fields: [
                {
                    key: 'title',
                    label: 'Job title',
                    sortable: true,
                    thStyle: { width: '40%' },
                },
                { key: 'new', class: 'table-column-center' },
                { key: 'accepted', class: 'table-column-center' },
                { key: 'rejected', class: 'table-column-center' },
                { key: 'pending', class: 'table-column-center' },
            ],
        }
    },

    methods: {
        fetchAllCompanies() {
            this.$store.state.backend
                .get('/jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
        },

        fetchDepartmentsFromCompany() {
            this.$store.state.backend
                .get(`/jobBuilder/project/all/${this.selectedCompany.id}`)
                .then((ret) => (this.departments = ret.data))
                .catch((error) => alert(error))
        },

        fetchJobsFromDepartment() {
            return this.$store.state.backend
                .get(
                    `/getjobs/byCompany/${this.selectedCompany.id}/department/${this.selectedDepartment.id}`
                )
                .then((ret) => (this.jobs = ret.data.jobs))
                .catch((error) => alert(error))
        },

        fetchJobsFromCompany() {
            this.$store.state.backend
                .get(`/getjobs/byCompany/${this.selectedCompany.id}`)
                .then((res) => (this.jobs = res.data.jobs))
                .catch((err) => alert(err))
        },

        onRowClicked(row) {
            this.$router.push({ name: ROUTE.DASHBOARD_JOB, params: { id: row.id, job: row } })
        },
    },
}
</script>
<style lang="sass" scoped>
label
  font-size: 14px
  font-weight: bold

table ::v-deep > tbody > tr
  cursor: pointer

table ::v-deep .table-column-center
  width: 15%
  text-align: center
</style>
